import React, { Component } from 'react';
import * as bootstrap from 'bootstrap';
import $ from 'jquery';
class BootstrapComponent extends Component {
    componentDidMount() {
        // Bootstrap Popover initialization
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        this.popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl);
        });

        // Bootstrap Tooltips initialization
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
        });

        // Mobile toggle navigation
        $('.mobile-toggle-nav').on('click', function () {
            $(this).toggleClass('is-active');
            $('.app-container').toggleClass('sidebar-mobile-open');
        });

        $('.mobile-toggle-header-nav').on('click', function () {
            $(this).toggleClass('active');
            $('.app-header__content').toggleClass('header-mobile-open');
        });

        // Responsive adjustments
        this.resizeClass = () => {
            const win = document.body.clientWidth;
            if (win < 1250) {
                $('.app-container').addClass('closed-sidebar-mobile closed-sidebar');
            } else {
                $('.app-container').removeClass('closed-sidebar-mobile closed-sidebar');
            }
        };

        $(window).on('resize', this.resizeClass);
        this.resizeClass();
    }

    componentWillUnmount() {
        $(window).off('resize', this.resizeClass);
        $('.mobile-toggle-nav').off('click');
        $('.mobile-toggle-header-nav').off('click');
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

export default BootstrapComponent;
