import React, { Component } from 'react'
import withNavigateHook from '../withNavigateHook';

class Header extends Component {

    handleClick = (event) => {
        event.preventDefault();
    }

    render() {
        return (
            <React.Fragment>
                <div className="app-header header-shadow">
                    <div className="app-header__logo">
                        <div className="logo-src"></div>
                        <div className="header__pane ms-auto">
                            <div>
                                <button type="button" className="hamburger close-sidebar-btn hamburger--elastic"
                                    data-class="closed-sidebar">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="app-header__mobile-menu">
                        <div>
                            <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="app-header__menu">
                        <span>
                            <button type="button"
                                className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                                <span className="btn-icon-wrapper">
                                    <i className="fa fa-ellipsis-v fa-w-6"></i>
                                </span>
                            </button>
                        </span>
                    </div>
                    <div className="app-header__content">
                        <div className="app-header-left">
                            {/* <div className="search-wrapper">
                                <div className="input-holder">
                                    <input type="text" className="search-input" placeholder="Type to search" />
                                    <button className="search-icon"><span></span></button>
                                </div>
                                <button className="btn-close"></button>
                            </div> */}
                            {/* <ul className="header-menu nav">
                                <li className="nav-item">
                                    <a href="#" onClick={this.handleClick} className="nav-link">
                                        <i className="nav-link-icon fa fa-database"> </i>
                                        Statistics
                                    </a>
                                </li>
                                <li className="btn-group nav-item">
                                    <a href="#" onClick={this.handleClick} className="nav-link">
                                        <i className="nav-link-icon fa fa-edit"></i>
                                        Projects
                                    </a>
                                </li>
                                <li className="dropdown nav-item">
                                    <a href="#" onClick={this.handleClick} className="nav-link">
                                        <i className="nav-link-icon fa fa-cog"></i>
                                        Settings
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                        <div className="app-header-right">
                            <div className="header-btn-lg pe-0">
                                <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                        <div className="widget-content-left">
                                            <div className="btn-group">
                                                <a data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                    className="p-0 btn">
                                                    <img width="42" className="rounded-circle" src="images/avatars/1.png" alt="" />
                                                    <i className="fa fa-angle-down ms-2 opacity-8"></i>
                                                </a>
                                                <div tabIndex="-1" role="menu" aria-hidden="true"
                                                    className="dropdown-menu dropdown-menu-right">
                                                    <button type="button" tabIndex="0" className="dropdown-item">User
                                                        Account</button>
                                                    <button type="button" tabIndex="0" className="dropdown-item">Settings</button>
                                                    <h6 tabIndex="-1" className="dropdown-header">Header</h6>
                                                    <button type="button" tabIndex="0" className="dropdown-item">Actions</button>
                                                    <div tabIndex="-1" className="dropdown-divider"></div>
                                                    <button type="button" tabIndex="0" className="dropdown-item">Dividers</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-content-left  ms-3 header-user-info">
                                            <div className="widget-heading">
                                                Raghvendra
                                            </div>
                                            <div className="widget-subheading">
                                                Tecnical Head / Developer
                                            </div>
                                        </div>
                                        {/* <div className="widget-content-right header-user-info ms-3">
                                            <button type="button"
                                                className="btn-shadow p-1 btn btn-primary btn-sm show-toastr-example">
                                                <i className="fa text-white fa-calendar pe-1 ps-1"></i>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withNavigateHook(Header);
