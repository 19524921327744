import React, { Component } from 'react'
import withNavigateHook from '../withNavigateHook'
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import MetisMenu from 'metismenu';
import BootstrapComponent from '../assets/BootstrapComponent';
import OtherFunctionalitiesComponent from '../assets/OtherFunctionalitiesComponent';
 


class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.menuRef = React.createRef();
        this.scrollbarRef = React.createRef();
    }
    componentDidMount() {
        // Initialize MetisMenu 
        setTimeout(() => { 
            new MetisMenu(this.menuRef.current); 
        }, 100);
        // Initialize PerfectScrollbar 
        setTimeout(() => { 
            if (this.scrollbarRef.current) {
                 new PerfectScrollbar(this.scrollbarRef.current); 
                } 
            }, 1000);
    }

    componentWillUnmount() {
        // Dispose MetisMenu instance if necessary
        // if (this.menuRef.current) {
        //     this.menuRef.current.dispose();
        // }
        // Destroy PerfectScrollbar instance if necessary 
        // if (this.scrollbarRef.current) {
        //      this.scrollbarRef.current.destroy(); 
        //  }
    }

    onClickHandler = (event) => {
        event.preventDefault();
    }



    render() {
        return (
            <React.Fragment>
                <BootstrapComponent />
                <OtherFunctionalitiesComponent />
                <div className="app-sidebar sidebar-shadow">
                    <div className="app-header__logo">
                        <div className="logo-src"></div>
                        <div className="header__pane ms-auto">
                            <div>
                                <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="app-header__mobile-menu">
                        <div>
                            <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="app-header__menu">
                        <span>
                            <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                                <span className="btn-icon-wrapper">
                                    <i className="fa fa-ellipsis-v fa-w-6"></i>
                                </span>
                            </button>
                        </span>
                    </div>
                    <div className="scrollbar-sidebar" ref={this.scrollbarRef}>
                        <div className="app-sidebar__inner">
                            <ul className="vertical-nav-menu" ref={this.menuRef}>

                                <li className="app-sidebar__heading">Front-Office Dashboard</li>
                                <li>
                                    <Link to="/dashboard" className="mm-active">
                                        <i className="metismenu-icon pe-7s-rocket"></i>
                                        Statistics
                                    </Link>
                                </li>
                                <li>

                                    <Link to="" onClick={this.onClickHandler}>
                                        <i className="metismenu-icon pe-7s-diamond"></i>
                                        Reservations
                                        <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                                    </Link>
                                    {/* <ul className="mm-active" > */}
                                    <ul >
                                        <li>
                                            <Link to="/live-reservations">
                                                <i className="metismenu-icon"></i>
                                                Live Reservations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <i className="metismenu-icon">
                                                </i>
                                                New Reservation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <i className="metismenu-icon">
                                                </i>
                                                Night Audit
                                            </Link>
                                        </li>

                                    </ul>
                                </li>
                                <li >
                                    <Link to="#">
                                        <i className="metismenu-icon pe-7s-car"></i>
                                        Guest Management
                                        <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                                    </Link>
                                    <ul >
                                        <li>
                                            <Link to="">
                                                <i className="metismenu-icon"></i>
                                                Guest Profiles
                                            </Link>
                                        </li>


                                    </ul>
                                </li>
                                <li >
                                    <Link to="#" onClick={this.onClickHandler}>
                                        <i className="metismenu-icon pe-7s-car"></i>
                                        Room Management
                                        <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                                    </Link>
                                    <ul >
                                        <li>
                                            <Link to="">
                                                <i className="metismenu-icon"></i>
                                                Room Status/Maintenance
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="app-sidebar__heading">Back-Office Dashboard</li>
                                <li>
                                    <Link to="">
                                        <i className="metismenu-icon pe-7s-rocket"></i>
                                        Property Onboard
                                    </Link>
                                </li>
                                <li >
                                    <Link to="#">
                                        <i className="metismenu-icon pe-7s-car"></i>
                                        Analytics
                                        <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                                    </Link>
                                    <ul >
                                        <li>
                                            <Link to="elements-buttons-standard.html">
                                                <i className="metismenu-icon"></i>
                                                Daily Report
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="elements-buttons-standard.html">
                                                <i className="metismenu-icon"></i>
                                                Occupancy Report
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="elements-buttons-standard.html">
                                                <i className="metismenu-icon"></i>
                                                Revenue Report
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li >
                                    <Link to="#" onClick={this.onClickHandler}>
                                        <i className="metismenu-icon pe-7s-car"></i>
                                        Room Rates
                                        <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                                    </Link>
                                    <ul >
                                        <li>
                                            <Link to="">
                                                <i className="metismenu-icon"></i>
                                                Rate Updates
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withNavigateHook(Sidebar);
