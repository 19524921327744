import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import '../assets/login.css';
import withNavigateHook from '../withNavigateHook';

class Login extends Component {

    /**
      * 
      */
    componentDidMount = async () => {
       
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                     <div className="login-box">
                        <form action="">
                            <h2 className='login-h2'>Admin Login</h2>

                            <div className="input-box">
                                <span className="icon">
                                <i className="pe-7s-mail"></i>
                                </span>
                                <input type="email" required />
                                <label>Email</label>
                            </div>

                            <div className="input-box">
                                <span className="icon">
                                <i className="pe-7s-lock"></i>
                                </span>
                                <input type="password" required />
                                <label>Password</label>
                            </div>

                            <div className="remember-forgot">
                                <label><input type="checkbox" /> Remember me</label>
                                <a href="#">Forgot Password?</a>
                            </div>

                            {/* <button className="login-button" type="submit">Login</button> */}
                            <Link className="btn login-button" to="dashboard"  > Login </Link>
                            <div className="register-button-link">
                                {/* <p>Don't have an account? <a href="#">Register</a></p> */}
                            </div>
                        </form>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

export default withNavigateHook(Login);
