import React, { Component } from 'react';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import withNavigateHook from '../withNavigateHook';
import Calendar from '../layouts/Calendar';


class LiveReservation extends Component {

    /**
      * 
      */
    componentDidMount = async () => {
      // import('../assets/scriptInit');
    }


    render() {
        return (
            <React.Fragment>
                <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                    <Header />
                    <div className="app-main">
                        <Sidebar />

                        <Calendar />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withNavigateHook(LiveReservation);
