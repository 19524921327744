import React, { Component } from 'react'
import withNavigateHook from '../withNavigateHook'
import LineChart from '../layouts/LineChart'
import LineChart2 from '../layouts/LineChart2'

class Statistics extends Component {

      /**
       * 
       */
    componentDidMount = async () => {
        
        
    }

    render() {
        return (
            <React.Fragment>
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <div className="app-page-title">
                            <div className="page-title-wrapper">
                                <div className="page-title-heading">
                                    <div className="page-title-icon">
                                        <i className="pe-7s-car icon-gradient bg-mean-fruit">
                                        </i>
                                    </div>
                                    <div>Reservation Statistics
                                        {/* <div className="page-title-subheading">This is an example dashboard created using
                                            build-in elements and components.
                                        </div> */}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xl-4">
                                <div className="card mb-3 widget-content bg-midnight-bloom">
                                    <div className="widget-content-wrapper text-white">
                                        <div className="widget-content-left">
                                            <div className="widget-heading">Total Orders</div>
                                            <div className="widget-subheading">Last year expenses</div>
                                        </div>
                                        <div className="widget-content-right">
                                            <div className="widget-numbers text-white"><span>1896</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="card mb-3 widget-content bg-arielle-smile">
                                    <div className="widget-content-wrapper text-white">
                                        <div className="widget-content-left">
                                            <div className="widget-heading">Clients</div>
                                            <div className="widget-subheading">Total Clients Profit</div>
                                        </div>
                                        <div className="widget-content-right">
                                            <div className="widget-numbers text-white"><span>$ 568</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="card mb-3 widget-content bg-grow-early">
                                    <div className="widget-content-wrapper text-white">
                                        <div className="widget-content-left">
                                            <div className="widget-heading">Followers</div>
                                            <div className="widget-subheading">People Interested</div>
                                        </div>
                                        <div className="widget-content-right">
                                            <div className="widget-numbers text-white"><span>46%</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-xl-none d-lg-block col-md-6 col-xl-4">
                                <div className="card mb-3 widget-content bg-premium-dark">
                                    <div className="widget-content-wrapper text-white">
                                        <div className="widget-content-left">
                                            <div className="widget-heading">Products Sold</div>
                                            <div className="widget-subheading">Revenue streams</div>
                                        </div>
                                        <div className="widget-content-right">
                                            <div className="widget-numbers text-warning"><span>$14M</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                                <div className="col-md-6">
                                    <div className="main-card mb-3 card">
                                        <div className="card-body">
                                            <h5 className="card-title">Vertical Bars</h5>
                                            <div className="chart-container">
                                                <LineChart />
                                                {/* <canvas id="chart-vert-bar"></canvas> */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                   
                                    <div className="main-card mb-3 card">
                                        <div className="card-body">
                                            <h5 className="card-title">Horizontal Bars</h5>
                                            <div className="chart-container">
                                                <LineChart2 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                    </div>

                </div>

            </React.Fragment>
        )
    }
}

export default withNavigateHook(Statistics);
