import React, { Component } from 'react';
import $ from 'jquery';

class OtherFunctionalitiesComponent extends Component {
    componentDidMount() {
        $('.btn-open-options').on('click', function () {
            $('.ui-theme-settings').toggleClass('settings-open');
        });

        $('.close-sidebar-btn').on('click', function () {
            const classToSwitch = $(this).attr('data-class');
            const containerElement = '.app-container';
            $(containerElement).toggleClass(classToSwitch);
            $(this).toggleClass('is-active');
        });

        $('.switch-container-class').on('click', function () {
            const classToSwitch = $(this).attr('data-class');
            const containerElement = '.app-container';
            $(containerElement).toggleClass(classToSwitch);
            $(this).parent().find('.switch-container-class').removeClass('active');
            $(this).addClass('active');
        });

        $('.switch-theme-class').on('click', function () {
            const classToSwitch = $(this).attr('data-class');
            const containerElement = '.app-container';
            $(containerElement).removeClass('body-tabs-shadow body-tabs-line');
            $(containerElement).addClass(classToSwitch);
            $(this).parent().find('.switch-theme-class').removeClass('active');
            $(this).addClass('active');
        });

        $('.switch-header-cs-class').on('click', function () {
            const classToSwitch = $(this).attr('data-class');
            const containerElement = '.app-header';
            $(containerElement).attr('class', 'app-header');
            $(containerElement).addClass('header-shadow ' + classToSwitch);
            $(this).parent().find('.switch-header-cs-class').removeClass('active');
            $(this).addClass('active');
        });

        $('.switch-sidebar-cs-class').on('click', function () {
            const classToSwitch = $(this).attr('data-class');
            const containerElement = '.app-sidebar';
            $(containerElement).attr('class', 'app-sidebar');
            $(containerElement).addClass('sidebar-shadow ' + classToSwitch);
            $(this).addClass('active');
            $(this).parent().find('.switch-sidebar-cs-class').removeClass('active');
        });
    }

    componentWillUnmount() {
        $('.btn-open-options').off('click');
        $('.close-sidebar-btn').off('click');
        $('.switch-container-class').off('click');
        $('.switch-theme-class').off('click');
        $('.switch-header-cs-class').off('click');
        $('.switch-sidebar-cs-class').off('click');
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

export default OtherFunctionalitiesComponent;
