
/* eslint-disable */
import React, { Component } from 'react';
import  { Routes, Route } from "react-router-dom";
 import App from '../App';
import Login from './pages/Login';
import Dashboard from './Dashboard';
import LiveReservation from './pages/LiveReservation';

export default class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {}

    /**
     * Setting the Routing for the Application
     * @returns 
     */
    render() {
        return (
            <React.Fragment>
                {/* <BrowserRouter basename="/"> */}
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/live-reservations" element={<LiveReservation />} />
                    </Routes>
                {/* </BrowserRouter> */}
            </React.Fragment>
        );
    }
}

