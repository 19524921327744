import React, { Component } from 'react'

import Header from './layouts/Header';
import Sidebar from './layouts/Sidebar';
import withNavigateHook from './withNavigateHook';
import Statistics from './pages/Statistics';


class Dashboard extends Component {
    /**
      * 
      */
    componentDidMount = async () => {
        //import('./assets/scriptInit');
    }

    render() {
        return (
            <React.Fragment>
                <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                    <Header />
                    <div className="app-main">
                        <Sidebar />
                        <Statistics />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withNavigateHook(Dashboard);
