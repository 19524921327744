import React, { Component } from 'react';
import Header from './components/layouts/Header';
import Sidebar from './components/layouts/Sidebar';
import Login from './components/pages/Login';
import Navigation from './components/Navigation';


export default class App extends Component {
 
  render() {
    return (
      <React.Fragment>
          <Navigation />
        {/* <Login /> */}
       
      </React.Fragment>
    );
  }
}

