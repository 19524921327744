import React from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import useAdminPanelFeatures from './assets/useAdminPanelFeatures';

const withNavigateHook = (Component) => {
    return (props) => {
        //useAdminPanelFeatures();
        // import('./assets/scriptInit');
        const navigation = useNavigate();
        let location = useLocation();
        return <Component navigation={navigation} location={location}  {...props} />
    }
}

export default withNavigateHook;