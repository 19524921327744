import React, { Component } from 'react'
import withNavigateHook from '../withNavigateHook.js';

 class Calendar extends Component {

    /**
      * 
      */
    componentDidMount = async () => {
        import('../assets/calendar.js');
    }



    render() {
        return (
            <React.Fragment>
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <div className="app-page-title">
                            <div className="page-title-wrapper">
                                <div className="page-title-heading">
                                    <div className="page-title-icon">
                                        <i className="pe-7s-car icon-gradient bg-warm-flame">
                                        </i>
                                    </div>
                                    <div>Calendar
                                        <div className="page-title-subheading">Calendars are used in a lot of apps. We thought
                                            to include one for React.
                                        </div>
                                    </div>
                                </div>
                                <div className="page-title-actions">
                                    
                                   
                                </div>
                            </div>
                        </div>

                        <div className="main-card mb-3 card">
                            <div className="card-body">
                                <div id='calendar'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withNavigateHook(Calendar);
